import { defineStore } from 'pinia';
import { useFetchClient, useRouter } from '#imports';

import type { IQuestionForm } from '@/types';
import { useCsrf, useRoute, useState, useCampaignStore } from '#build/imports';

export interface IFormState {
  campaignId: string;
  total: number;
  answered: number;
  newQuestionValue: any;
  newCommentValue: any;
  question: IQuestionForm;
  questions?: Array<IQuestionForm>;
}

export const useFormStore = defineStore('form', {
  state: (): IFormState => ({
    campaignId: '',
    total: 0,
    answered: 0,
    newQuestionValue: null,
    newCommentValue: null,
    question: {
      id: '',
      type: '',
      status: 'waiting',
      choices: 0,
      number: 0,
      total: 0,
      progress: 0,
      question: '',
      instruction: '',
      value: null,
      comment: '',
      options: [],
      hasComment: true,
      hasNext: true,
      isRequired: true,
      isDemographic: false
    },
    questions: [],
  }),
  getters: {
    getQuestionById: (state) => {
      return (id?: string): IQuestionForm | null => state.questions?.find((question) => question.id === id) || null;
    },
    getQuestionByNumber: (state) => {
      return (number?: number): IQuestionForm | null =>
        state.questions?.find((question) => question.number === number) || null;
    },
  },
  actions: {
    async sendQuestion(value?: number) {
      const route = useRoute();

      if (this.campaignId !== route.params.research) {
        this.$reset();
      }

      this.campaignId = route.params.research;

      let question: IQuestionForm | null = this.getQuestionByNumber(value);

      if (!question) {
        const route = useRoute();

        let url = `/api/forms/${route.params.form}`;
        let query = {};

        if (value) {
          query.question = value.toString();
        }

        // Caso o value não exista, será retornado a última questão ativa.
        const { data, error } = await useFetchClient(url, { query });

        if (error.value) return;

        question = data.value;

        this.changeQuestions(data.value);
      }

      this.question = question;
      this.newQuestionValue = question.value;
      this.newCommentValue = question.comment;

      return question;
    },
    async sendAnswer(wasSkipped: boolean = false) {
      const { csrf } = useCsrf();
      const { getCampaignById } = useCampaignStore();
      const user = useAuthUser();
      const router = useRouter();
      const route = useRoute();

      const loading = useState('loading');
      const campaign = getCampaignById(route.params.research);

      loading.value = true;

      const body = { value: this.newQuestionValue, comment: this.newCommentValue, wasSkipped: false };

      if (wasSkipped) {
        body.wasSkipped = true;
      }

      const { data: nextQuestion, error } = await useFetchClient(`/api/forms/answer/${this.question.id}`, {
        method: 'PUT',
        body: { ...body, _csrf: csrf },
      });

      if (error.value) return;

      if (!this.question.hasNext) {
        if (!user.value?.email && campaign?.type === 'boosterh') {
          router.push({ name: 'research-interaction', params: { research: route.params.research } });
        } else {
          router.push({ name: 'research-finish', params: { research: route.params.research } });
        }

        return;
      }

      this.answerQuestion();
      this.addQuestion(nextQuestion.value);
      this.newQuestionValue = nextQuestion.value?.value;
      this.newCommentValue = nextQuestion.value?.comment;

      this.question = nextQuestion.value;

      loading.value = false;
    },
    async moveQuestion(type: 'next' | 'prev') {
      const numberQuestion = type === 'next' ? this.question.number + 1 : this.question.number - 1;

      if (!this.question.hasNext) {
        this.question.value = this.newQuestionValue;
        this.question.comment = this.newCommentValue;
      }

      if (
        this.question.type !== 'radio' &&
        this.question.type !== 'likert' &&
        this.question.value !== this.newQuestionValue &&
        this.question.hasNext
      ) {
        await this.sendAnswer();
      } else {
        await this.sendQuestion(numberQuestion);
      }
    },
    changeQuestions(data: IQuestionForm) {
      const question = this.getQuestionById(data.id);

      if (!question) {
        this.addQuestion(data);
      } else {
        this.questions.splice(question, 1, data);
      }
    },
    answerQuestion() {
      const oldQuestion = this.getQuestionById(this.question.id);

      this.question.value = this.newQuestionValue;
      this.question.comment = this.newCommentValue;

      this.questions.splice(oldQuestion, 1, this.question);
    },
    addQuestion(data: IQuestionForm) {
      this.questions.push(data);
    },
  },
});
